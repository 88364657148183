import { cacheAdapterEnhancer } from 'axios-extensions';
import i18n from '@/lib/vue-i18n/i18n';
import axios, { AxiosAdapter, AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import * as env from 'env-var';
import { ErrorResponse, FailResponse, FoodFormErrors } from '@/module/shared/general/types/FoodApiResponse';
import ToastService from '@/module/shared/general/services/ToastService';
import notify from '@/util/notify';

interface ResponseNotification {
    message: string
    type: string
}

const cacheConfig = {
    enabledByDefault: false,
    cacheFlag: 'useCache',
};

const AxiosScaleApiClient = axios.create({
    timeout: env.get('NODE_ENV').required().asString() === 'development' ? 30000 : 30000,
    headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'X-Requested-With': 'XMLHttpRequest'
    },
    adapter: cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, cacheConfig),
    withCredentials: true,
});

function handleNotifications(response: AxiosResponse) {
    const notifications = response?.data?.notifications;

    if (!notifications) {
        return;
    }

    for (const notification of notifications as ResponseNotification[]) {
        notify(notification.type, notification.message);
    }

    return notifications.length > 0;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const responseInterceptor = (response: AxiosResponse): any => {
    if (response.request.responseURL.includes('/login')) {
        window.location.href = '/login';
    }

    handleNotifications(response);

    return response.data.data;
};

const errorInterceptor = (error: AxiosError<FailResponse | ErrorResponse>): Promise<FoodFormErrors> => {
    const hadNotifications = handleNotifications(error.response);

    if (
        !hadNotifications && (
        typeof error.response === 'undefined' || (
            typeof error.response.data !== 'undefined'
            && error.response.data.status === 'error'
        ))
    ) {
        notify('danger', i18n.t('shared.error.network_fail').toString());

        console.error(error.message);

        return Promise.reject(error);
    }

    return Promise.reject(error.response.data.data.errors);
};

AxiosScaleApiClient.interceptors.response.use(responseInterceptor, errorInterceptor);

class ScaleApiClient {
    get<R>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return AxiosScaleApiClient.get(url, config);
    }

    post<R>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
        return AxiosScaleApiClient.post(url, data, config);
    }

    put<R>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> {
        return AxiosScaleApiClient.put(url, data, config);
    }

    delete<R>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return AxiosScaleApiClient.delete(url, data, config);
    }
}

const ScaleApiClient = new ScaleApiClient();

export default ScaleApiClient;
