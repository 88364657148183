import Vue from 'vue';
import VueI18n from 'vue-i18n';
import * as env from 'env-var';
import nl from '@/translations/nl';
import en from '@/translations/en';

Vue.use(VueI18n);

const availableLanguages = ['nl', 'en'];
const requestedLanguage = 'nl';

// navigator.languages.forEach((language) => {
//     if (availableLanguages.includes(language)) {
//         requestedLanguage = language;
//     }
// });

const i18n = new VueI18n({
    locale: requestedLanguage,
    messages: { nl, en },
    silentTranslationWarn: env.get('NODE_ENV').required().asString() !== 'development',
    fallbackLocale: ['en', 'nl']
});

export default i18n;
